import request from "@/utils/http";

// 修改个人基本信息
export function profileEdit(data) {
  return request({
    url: "/profile/edit",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}
// 个人基本信息
export function getuser(params) {
  return request({
    url: "/profile",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}

// 查询用户列表
export function getuserList(params) {
  return request({
    url: "/admin/user/list",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}

// 修改用户账号状态
export function userStatus(data) {
  return request({
    url: "/admin/user/status",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

// 查询用户信息
export function getuserInfo(params) {
  return request({
    url: "/admin/user",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}

// 查询审核列表
export function getauditList(params) {
  return request({
    url: "/admin/audit/list",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}

// 进行审核
export function audit(data) {
  return request({
    url: "/admin/audit",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

// 查看用户套餐余量
export function getasset(params) {
  return request({
    url: "/asset",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}

//  查询订单列表
export function getOrderList(params) {
  return request({
    url: "/admin/order/list",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}

//   购买记录
export function getUserOrder(params) {
  return request({
    url: "/order/list",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}

// 查询API列表
export function getApiList(params) {
  return request({
    url: "/api/list",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}
// 创建API
export function createApi(data) {
  return request({
    url: "/api/create",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}
// 删除API
export function deleteApi(data) {
  return request({
    url: "/api/delete",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

// 查看已申请api的用户列表
export function getAdminApi(params) {
  return request({
    url: "/admin/api_user/list",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}

// 查看单个用户的API列表
export function getAdminUserApi(params) {
  return request({
    url: "/admin/api/list",
    method: "get",
    headers: {
      isToken: true,
    },
    params: params,
  });
}
