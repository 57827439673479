<template>
  <div class="copyright">
      @ 2023 ccsmec.com <a style="display: inline-block;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备2022003475号</a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .copyright{
        width: 100%;
        padding: 20px 0;
        background: #F4F3F3;
        text-align: center;
        font-size: 16px;
        color: #606266;
        a{
            color: #606266;
        }
    }
</style>