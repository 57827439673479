import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon'// svg component

/*
require.context有三个参数：
directory：说明小组要检索的目录
useSubdirectories：是否检索子目录
regExp：匹配文件的正则表达式
*/
// 全局注册
Vue.component('svg-icon', SvgIcon)

const req = require.context('./svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

