
import { checkFileName } from "@/api/evidence";
// 第六版 防抖
export function debounce(func, wait, immediate) {

    var timeout, result;

    var debounced = function () {
        var context = this;
        var args = arguments;

        if (timeout) clearTimeout(timeout);
        if (immediate) {
            // 如果已经执行过，不再执行
            var callNow = !timeout;
            timeout = setTimeout(function () {
                timeout = null;
            }, wait)
            if (callNow) result = func.apply(context, args)
        }
        else {
            timeout = setTimeout(function () {
                func.apply(context, args)
            }, wait);
        }
        return result;
    };

    debounced.cancel = function () {
        clearTimeout(timeout);
        timeout = null;
    };

    return debounced;
}


export function checkPhone(rule, value, callback) {
    if (value === "") {
        callback(new Error("请输入联系人手机号"));
    } else if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号码"));
    } else {
        callback();
    }
}

export function checkVerificationCode(rule, value, callback) {
    if (value === "") {
        callback(new Error("请输入验证码"));
    } else if (!/^\d{6}$/.test(value)) {
        callback(new Error("请输入正确的验证码"));
    } else {
        callback();
    }
}
export function checkFileNameDuplicate(rule, value, callback) {
    // if (value === '') {
    //     return callback(new Error("请输入文件名"))
    // }

    checkFileName({ file_name: value }).then(res => {
        console.log(`res is `, res)
        if (res?.data?.status) {
            callback()
        } else {
            callback(new Error("文件名重复!"))
        }
    }).catch(() => {
        callback()
    })
}