<template>
  <section class="container">
    <router-view :key="key"></router-view>
  </section>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    key() {
      return this.$route.path;
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>

</style>