// vue引入全局组件的两种方式
// 第一种：在main.js中直接注册
// 第二种：使用插件的形式注册

// 引入组件
import TopBar from './components/topBar.vue'
import FooterBar from './components/footer.vue'
import SliderBar from './components/sliderBar.vue'
export default {
  install(Vue) {
  // 注册全局组件
    Vue.component('TopBar', TopBar)
    Vue.component('FooterBar', FooterBar)
    Vue.component('SliderBar', SliderBar)
  }
}