<template>
  <div class="addcredit">
    <top-bar />
    <div class="content">
      <div class="innenr">
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/credit/index' }">区块链存证</el-breadcrumb-item>
          <el-breadcrumb-item>新增存证</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="title">新增存证</div>

        <el-form ref="form" :rules="rules" :model="formData" class="formBox">
          <el-form-item class="item" prop="type">
            <div class="label"><span>*</span>存证类型</div>
            <div class="checkBox">
              <div class="checkBox-item" :class="{ active: formData.type === item.type }"
                v-for="(item, index) in creditType" :key="index" @click="selectType(item)">
                <div class="left"></div>
                <div class="right">
                  <div class="name">
                    <svg-icon v-if="index == 0" icon-class="haxi" style="font-size: 22px; margin-right: 10px" />
                    <svg-icon v-else icon-class="yuanwenjian" style="font-size: 25px; margin-right: 10px" />
                    {{ item.name }}
                  </div>
                  <div class="desc">{{ item.desc }}</div>
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item class="item" prop="file_name">
            <div class="label"><span>*</span>文件名称</div>
            <el-input maxlength="20" v-model.trim="formData.file_name" placeholder="最多20个字符，且不可用：/：*？#<>|。"></el-input>
          </el-form-item>

          <el-form-item class="item">
            <div class="label"><span>*</span>文件上传</div>
            <el-upload class="upload" action="" :show-file-list="false" :before-upload="beforeAvatarUpload">
              <div class="upload-innner">
                <svg-icon icon-class="upload" style="margin-right: 6px; font-size: 18px; color: #606266" />
                <span style="color: #001420">选择文件</span>
              </div>
            </el-upload>
          </el-form-item>
          <div class="licence-detail" v-if="orifile_name">
            <div class="left">
              <svg-icon style="margin: 0 10px 0 15px; font-size: 18px" icon-class="lianjie" />
              <span class="left-licenseName">{{ orifile_name }}</span>
            </div>
            <div class="right">
              <svg-icon style="margin-right: 20px; font-size: 18px" icon-class="shanchu" @click="deleteImg" />
              <div v-if="canPreview" class="preview" @click="preview">预览</div>
            </div>
          </div>
          <div class="uploadTip">
            点击上传文件，一次仅支持上传一个文件；<br />
            支持20MB以内word、pdf、jpg/png、音视频等各种格式的文件
          </div>
        </el-form>

        <el-button class="nowcredit" ype="primary" :disabled="!Boolean(orifile_name && formData.file_name)"
          :loading="loadingBtn" @click="onSubmit('form')">立即存证</el-button>

        <div class="tips">
          <div class="tips-tit">说明：</div>
          <p>1、所选文件将按照国密SHA256算法计算Hash；</p>
          <p>2、根据您选择的存证类型，链上可存储原文件，也可存储原文件Hash；</p>
          <p>3、文件单次存证后，保存三年，到期后可消耗一次“存证次数”以续存；</p>
          <p>
            4、请您依法自觉遵守健康网络秩序并维护网络信息安全，请勿上传违法、反动、危害社会公共安全以及侵犯他人合法权益等内容。当您上传、评论、展示或以其他交互行为违反公序良俗的，其后果由您自行承担，我司将根据法律规定作出处理，请您理解；
          </p>
          <p>
            5、请您注意，存证行为由您本人自行操作完成，平台仅进行区块链存证，不对您上传的内容负责，也不进行真实性验证；
          </p>
          <p>
            6、当您选择使用存证服务，为了帮您实现上链存证，原文上链需要存储原文件并在后台进行加密处理；当您选择哈希上链和本地化调用API的，平台不存储任何原文件信息。
          </p>
        </div>
      </div>
    </div>
    <!-- 预览图片 -->
    <div class="mask" v-show="previewFlag" @click="$event => { previewFlag = false; }">
      <img src="" id="show" width="200">
    </div>
  </div>
</template>

<script>
import { evidenceAdd } from "@/api/evidence";
import { messageParam } from '@/utils/xawz';
import { checkFileNameDuplicate } from '@/utils/tools';

export default {
  components: {},
  props: {},
  data() {
    let validateInput = (rule, value, callback) => {
      if (!this.checkSpecialKey(value)) {
        callback(new Error("最多20个字符，且不可用：/:*？#<>|。"));
      } else {
        callback();
      }
    };
    return {
      // creditTyped: 1,
      loadingBtn: false, // 按钮加载状态
      creditType: [
        {
          type: 1,
          name: "哈希存证",
          desc: "哈希存证，指链上仅存证文件hash，不存证原文件",
        },
        {
          type: 2,
          name: "原文存证",
          desc: "原文件存证，指用户上传文件后，链上存证原文件",
        },
      ],
      orifile_name: "",
      formData: {
        file: "",
        file_name: "",
        type: 1,
      },
      rules: {
        type: [{ required: true }],
        file_name: [
          {
            required: true,
            max: 20,
            message: "请输入文件名称",
            trigger: "blur",
          },

          { validator: checkFileNameDuplicate, trigger: "blur" },
          { validator: validateInput, trigger: "change" },
        ],
      },
      previewImg: [],
      previewFlag: false,
    };
  },
  computed: {
    canPreview: function () { // 是否可以显示预览
      return ['.png', '.jpg', '.jpeg', '.bmp', '.gif', '.webp', '.psd', '.svg', '.tiff'].some(type => {
        return this.orifile_name.toLowerCase().includes(type)
      })
    }
  },
  methods: {
    // 点击图片预览
    preview() {
      console.log("点击了预览")
      this.previewFlag = true;

    },
    // 校验输入框不能有特殊字符
    checkSpecialKey(str) {
      let specialKey = `/、：:*?？#《<>》|。."“”`;

      for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    // 选择文件上传
    beforeAvatarUpload(file) {
      console.log(file);
      this.formData.file = "";
      this.$loading({
        lock: true,
        text: "图片上传中...",
        background: "rgba(0, 0, 0, 0)",
      });
      const isLt20M = file.size / 1024 / 1024 < 20;

      console.log(!isLt20M);
      if (!isLt20M) {
        this.$loading().close();
        this.orifile_name = "";
        this.formData.file = "";
        const message = "上传的文件不能大于20MB！";
        this.$message.error({ message, ...messageParam });
      } else {
        this.orifile_name = file.name;
        this.formData.file = file;
        // this.formData.file_name = file.name;
        this.$loading().close();
        var reads = new FileReader();
        // f=document.getElementById('file').files[0];
        reads.readAsDataURL(this.formData.file);
        reads.onload = function (e) {
          document.getElementById('show').src = this.result;
        };
      }

      return isLt20M;
    },
    // 删除上传的图片
    deleteImg() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formData.file = "";
          // this.formData.file_name = "";
          this.orifile_name = "";
          this.$message({
            type: "success",
            message: "删除成功!",
            ...messageParam
          });
        })
        .catch(() => {
        });
    },
    selectType(item) {
      // this.creditTyped = item.type;
      this.formData.type = item.type;
    },
    // 提交表单
    onSubmit(formName) {
      console.log(this.formData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingBtn = true;
          evidenceAdd(this.formData).then(res => {
            this.$message({
              showClose: true,
              message: "新增存证成功！",
              type: "success",
              ...messageParam
            });
            this.loadingBtn = false;
            setTimeout(() => {
              this.$router.push({ path: "/creditdetail", query: { id: res.data.id } });
            }, 2000)

          }).catch(() => {
            this.loadingBtn = false;
          })

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() { },
  mounted() { },
};
</script>
<style lang="scss" scoped>
.addcredit {
  position: relative;
  min-height: 100vh;

  .content {
    width: 536px;
    // margin-left: 460px;
    position: relative;

    // 面包屑导航
    ::v-deep .el-breadcrumb {
      position: absolute;
      top: 40px;
      font-size: 13px;
      color: #001420;

      .el-breadcrumb__inner.is-link {
        color: #93989A;
      }

      .el-breadcrumb__item:last-child .el-breadcrumb__inner {
        font-weight: bold;
        color: #001420;
      }
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #001420;
      line-height: 32px;
      padding: 98px 0 30px;
    }

    .tips {
      font-size: 12px;
      color: #93989a;
      line-height: 16px;
      padding-bottom: 116px;

      .tips-tit {
        font-size: 16px;
        font-weight: bold;
        color: #001420;
        line-height: 21px;
        margin-bottom: 12px;
      }

      p {
        margin-bottom: 12px;
      }
    }

    // 表单
    .formBox {
      .item {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: normal;
        color: #606266;
        line-height: 18px;

        .label {
          margin-right: 30px;

          span {
            font-size: 14px;
            font-weight: normal;
            color: #ff0000;
            line-height: 18px;
          }
        }

        ::v-deep .el-input,
        ::v-deep input {
          width: 440px;
          height: 40px;
          background: #ffffff;
          border-radius: 4px;
          border-width: 0;
        }

        ::v-deep input {
          border: 1px solid #dddddd;
          padding: 0 20px;
        }
      }

      .checkBox {
        display: flex;
        font-size: 12px;
        font-weight: normal;

        color: #001420;
        line-height: 16px;

        &-item {
          cursor: pointer;
          width: 210px;
          height: 110px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dddddd;
          padding: 19px;
          box-sizing: border-box;
          display: flex;

          &:first-child {
            margin-right: 20px;
          }

          .left {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #ffffff;
            border: 1px solid #dddddd;
            margin-right: 12px;

          }



          &.active,
          &.active .left {
            border-color: #1677ff;
            position: relative;
          }

          &.active .left::before {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            background: #1677ff;
            border-radius: 50%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          .right {
            flex: 1;
          }

          .name {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #001420;
            line-height: 18px;
            margin-bottom: 12px;
          }

        }
      }

      ::v-deep .el-form-item__error {
        padding-left: 92px;
        padding-top: 10px;
      }

      ::v-deep .el-form-item__content {
        display: flex;
        align-items: center;
      }

      .upload-innner {
        width: 120px;
        height: 40px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .uploadTip {
        width: 100%;
        margin-top: 25px;
        margin-left: 88px;
        font-size: 14px;
        font-weight: normal;
        color: #93989a;
        line-height: 20px;
      }

      // 上传文件回显
      .licence-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 440px;
        height: 32px;
        background: rgba(64, 128, 255, 0.06);
        font-size: 12px;
        font-weight: normal;
        color: #606266;
        line-height: 16px;
        margin-top: -22px;
        margin-left: 88px;
        position: relative;

        .left {
          display: flex;
          align-items: center;
        }

        .left-licenseName {
          max-width: calc(440px - 18px - 100px);
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap
        }

        .preview {
          cursor: pointer;
          padding: 8px 19px;
          font-size: 12px;
          color: #4080FF;
          position: absolute;
          top: 0;
          right: 30px;
        }
      }
    }
  }

  ::v-deep button {
    width: 120px;
    height: 40px;
    margin: 60px 0 37px 88px;
    background: #4080ff;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
  }

  ::v-deep .is-disabled,
  ::v-deep .is-disabled:hover {
    border-width: 0;
    background: rgba(64, 128, 255, 0.3);
    color: #fff;
  }
}

// 图片预览弹窗
.mask {
  background: rgba(0, 0, 0, .4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  img {
    width: 600px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
