import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css' //引入全局样式
import '@/assets/css/global.css' //引入全局覆盖样式
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Components from './components'
import '@/icons'
import VueClipBoard from 'vue-clipboard2' // 复制插件



import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  'inline': false, //启用inline模式
  'button': true, //显示右上角关闭按钮
  'navbar': false, //显示缩略图导航
  'title': false, //显示当前图片的标题
  'toolbar': false, //显示工具栏
  'tooltip': true, //显示缩略百分比
  'movable': true, //图片是否可移动
  'zoomable': true, //图片是否可缩放
  'rotatable': true, //图片是否可旋转
  'scalable': true, //图片是否可反转
  'transition': true, //使用css3过度
  'fullscreen': false, //播放时是否全屏
  'keyboard': true, // 是否支持键盘
})

Vue.use(Components);
Vue.use(ElementUI);
Vue.use(VueClipBoard);

Vue.config.productionTip = false
// 加密手机号码
Vue.filter('filterTel', (val)=> {
  if(val){
    return (val.substring(3, 0)) + '****' + (val.substring(7));
  }
})
// 使用过滤器：{{ xxx | 过滤器名}}  或  v-bind:属性 = "xxx | 过滤器名"

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')