import request from '@/utils/http'

// 登录方法
export function login(name, password) {
  const data = {
    name,
    password,
  }
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function signup(data) {
  return request({
    url: '/signup',
    headers: {
      isToken: false,
      "content-type": "multipart/form-data"
    },
    method: 'post',
    data: data
  })
}

// 获取用户基本信息
export function getInfo() {
  return request({
    url: '/profile',
    method: 'get',
    headers: {
      isToken: true
    },
  })
}

// 退出登录
export function logout() {
  return request({
    url: '/logout',
    method: 'post',
    headers: {
      isToken: true
    },
  })
}

// 获取验证码
export function getSmsCode(data) {
  return request({
    url: '/send_code',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data,
    timeout: 20000
  })
}

// 校验验证码
export function validateCode(params) {
  return request({
    url: '/code/validate',
    method: 'get',
    headers: {
      isToken: true
    },
    params: params
  })
}

