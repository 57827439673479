import axios from "axios";
import router from "@/router";
import { Notification, MessageBox, Message, Loading } from "element-ui";
import store from "@/store";
import { getToken } from "./auth";
import errorCode from "./errorCode";
import { tansParams, blobValidate, messageParam } from "./xawz";
// import { saveAs } from 'file-saver'

let downloadLoadingInstance;
// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 对应国际化资源文件后缀
axios.defaults.headers["Content-Language"] = "zh_CN";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.NODE_ENV == "development" ? "/myapi" : "https://credit.ccsmec.com/api",
  // 超时
  timeout: 10000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (
      !isRepeatSubmit &&
      (config.method === "post" || config.method === "put")
    ) {
      const requestObj = {
        url: config.url,
        data:
          typeof config.data === "object"
            ? JSON.stringify(config.data)
            : config.data,
        time: new Date().getTime(),
      };
      // const s_url = sessionObj.url;                  // 请求地址
      // const s_data = sessionObj.data;                // 请求数据
      // const s_time = sessionObj.time;                // 请求时间
      // const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      // if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
      //   const message = '数据正在处理，请勿重复提交';
      //   console.warn(`[${s_url}]: ` + message)
      //   return Promise.reject(new Error(message))
      // }
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    if (code === 500) {
      Message({ center: true, message: msg, type: "error", ...messageParam });
      return Promise.reject(new Error(msg));
    } else if (code === 601) {
      Message({ center: true, message: msg, type: "error", ...messageParam });
      return Promise.reject("error");
    } else if (code !== 200) {
      // Notification.error({ title: msg });
      console.log(`code is `, code)
      Message({ message: msg, type: "error", duration: 5 * 1000, ...messageParam });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log("响应失败：" + error);
    let { message, response } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      // message = "系统接口" + message.substr(message.length - 3) + "异常";

      console.log(response);
      console.log(router.currentRoute.path)
      if (message.includes(401) && router.currentRoute.path != "/login") {
        isRelogin.show = true;
        message = "登录已失效";
        setTimeout(() => {
          router.replace({ path: '/login' })
        }, 500);
      } else {
        message = response.data.msg;
      }

    } else {
      message = "服务异常请稍后再试！";
    }
    Message({ message: message || "服务器开小差了，请稍后再试~", type: "error", duration: 5 * 1000, ...messageParam });
    return Promise.reject(error);
  }
);

// 通用下载方法
export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({
    text: "正在下载数据，请稍候",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  return service
    .post(url, params, {
      transformRequest: [
        (params) => {
          return tansParams(params);
        },
      ],
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      responseType: "blob",
      ...config,
    })
    .then(async (data) => {
      const isLogin = await blobValidate(data);
      if (isLogin) {
        const blob = new Blob([data]);
        // saveAs(blob, filename)
      } else {
        const resText = await data.text();
        const rspObj = JSON.parse(resText);
        const message =
          errorCode[rspObj.code] || rspObj.msg || errorCode["default"];
        // Message.error(errMsg);
        Message.error({ message, center: true });
      }
      downloadLoadingInstance.close();
    })
    .catch((r) => {
      console.error(r);
      Message.error({ message: "下载文件出现错误，请联系管理员！", center: true });
      downloadLoadingInstance.close();
    });
}

export default service;
