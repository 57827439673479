import Vue from "vue";
import VueRouter from "vue-router";
import { getToken } from "@/utils/auth"
import indexView from "../views/index.vue";
import application from "../views/home/application.vue";
import login from "../views/home/login.vue";

// 存证
import addcredit from "../views/credit/addcredit.vue";
import creditdetail from "../views/credit/detail.vue";
import certificate from "../views/credit/certificate.vue"; // 证书
import verification from "../views/credit/verification.vue"; // 验证数据

// 用户中心
import layout from "../views/layout/layout";
import userIndex from "../views/user/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: indexView,
    meta: {
      requireAuth: false,
      title: '区块链可信存证',
      description: `芯安链可信存证产品采用区块链技术，针对企业客户大量电子数据（包含图
      片、文件、音频、视频等）需多方共识、不可篡改的业务需求而设计打造，
      提供一整套“信任”解决方案。`

    }
  },
  {
    path: "/application",
    name: "application",
    component: application,
    meta: {
      requireAuth: false,
      title: '企业账号申请'
    },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      requireAuth: false,
      title: '登录'
    },
  },

  // 存证
  {
    path: "/credit/index",
    name: "creditIndex",
    component: () => import('../views/credit/index'),
    meta: {
      requireAuth: true,
      title: '区块链存证'
    },
  },
  // 添加存证
  {
    path: "/addcredit",
    name: "addcredit",
    component: addcredit,
    meta: {
      requireAuth: true,
      title: '添加存证'
    },
  },
  // 存证详情
  {
    path: "/creditdetail",
    name: "creditdetail",
    component: creditdetail,
    meta: {
      requireAuth: true,
      title: '存证详情'
    },
  },
  // 证书
  {
    path: "/certificate",
    name: "certificate",
    component: certificate,
    meta: {
      requireAuth: true,
      title: '区块链证书'
    },
  },
  // 验证数据
  {
    path: "/verification",
    name: "verification",
    component: verification,
    meta: {
      requireAuth: true,
      title: '验证数据'
    },
  },

  {
    path: "/goods/index",//产品介绍
    name: "buyRecord",
    component: () => import('../views/goods/index'),
    meta: {
      requireAuth: false,
      title: '产品介绍'
    },
  },
  {
    path: "/goods/pay",// 确认订单
    name: "pay",
    component: () => import('../views/goods/pay'),
    meta: {
      requireAuth: true,
      title: '确认订单'
    },
  },

  // 用户中心
  {
    path: "/user",
    component: layout,
    meta: {
      requireAuth: true,
      title: '用户中心'
    },
    children: [
      {
        path: "/user/index",
        name: "userIndex",
        // component: userIndex,
        component: () => import('../views/user/index'),
        meta: {
          requireAuth: true,
          title: '用户中心-基本信息'
        },
      },
      {
        path: "/user/buyRecord",//购买记录
        name: "buyRecord",
        component: () => import('../views/user/buyRecord'),
        meta: {
          requireAuth: true,
          title: '用户中心-购买记录'
        },
      },
      {
        path: "/user/api", // API列表
        name: "userApi",
        component: () => import('../views/user/api'),
        meta: {
          requireAuth: true,
          title: '用户中心-API列表'
        },
      },
      {
        path: "/companyList", // 企业用户列表
        name: "companyList",
        component: () => import('../views/user/company'),
        meta: {
          requireAuth: true,
          title: '用户中心-企业用户列表'
        },
      },
      {
        path: "/companyDeatil", // 企业用户详情
        name: "companyDeatil",
        component: () => import('../views/user/companyDeatil'),
        meta: {
          requireAuth: true,
          title: '企业用户详情'
        },
      },
      {
        path: "/applicationList", // 新申请列表
        name: "applicationList",
        component: () => import('../views/user/application'),
        meta: {
          requireAuth: true,
          title: '用户中心-新申请列表'
        },
      },
      {
        path: "/applicationDetail", // 新申请列表详情
        name: "applicationDetail",
        component: () => import('../views/user/applicationDetail'),
        meta: {
          requireAuth: true,
          title: '用户中心-新申请列表详情'
        },
      },
      {
        path: "/user/orderList",//订单列表
        name: "orderList",
        component: () => import('../views/user/orderList'),
        meta: {
          requireAuth: true,
          title: '用户中心-订单列表'
        },
      },
      {
        path: "/user/orderDetail",//订单详情
        name: "orderDetail",
        component: () => import('../views/user/orderDetail'),
        meta: {
          requireAuth: true,
          title: '用户中心-订单详情'
        },
      },
      {
        path: "/admin/api",//API列表
        name: "adminApi",
        component: () => import('../views/user/adminApi'),
        meta: {
          requireAuth: true,
          title: '用户中心-API列表'
        },
      },
      {
        path: "/admin/apiDetail",//API详情
        name: "adminApiDetail",
        component: () => import('../views/user/adminApiDetail'),
        meta: {
          requireAuth: true,
          title: '用户中心-API详情'
        },
      },
    ],
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("../views/aboutUs.vue"),
    meta: {
      requireAuth: false,
      title: '关于我们'
    },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () => import("../views/privacyPolicy.vue"),
    meta: {
      requireAuth: false,
      title: '隐私协议'
    },
  },
  {
    path: "/termsOfUse",
    name: "termsOfUse",
    component: () => import("../views/termsOfUse.vue"),
    meta: {
      requireAuth: false,
      title: '平台服务协议'
    },
  }
];

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  routes,
});

/* 在路由守卫router.beforeEach中设置如下代码 */
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.requireAuth && !getToken()) {
    next({ path: '/login' });
  }
  next();
});


export default router;
