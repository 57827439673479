<template>
  <div class="detail">
    <top-bar />
    <div class="detail-content">
      <!-- 面包屑导航 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/credit/index' }">区块链存证</el-breadcrumb-item>
        <el-breadcrumb-item>查看存证详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tit">查看存证详情</div>
      <ul>
        <li>
          <div class="label">文件名称</div>
          <div class="text">{{ credit.file_name }}</div>
        </li>
        <li>
          <div class="label">存证类型</div>
          <div class="text">{{ credit.save == 1 ? "哈希存证" : "原文件存证" }}</div>
        </li>
        <li>
          <div class="label">加密算法</div>
          <div class="text">SHA256算法</div>
        </li>
        <li v-if="credit.save == 2">
          <div class="label">原文件</div>
          <a style="text-decoration: underline;font-size: 16px;" class="text" :href="credit.file_uri" download
            target="downloadFile">下载原文件</a>
        </li>
        <li>
          <div class="label">区块高度</div>
          <div class="text">{{ credit.block_high }}</div>
        </li>
        <li>
          <div class="label">存证哈希</div>
          <div class="text">{{ credit.file_hash }}</div>
        </li>
        <li>
          <div class="label">存证时间</div>
          <div class="text">{{ credit.created_at }}</div>
        </li>
      </ul>

      <!-- 查看按钮 -->
      <el-button type="primary" class="lookup" @click="lookupHandler">查看存证证书</el-button>
    </div>
  </div>
</template>

<script>
import { evidenceQuery } from "@/api/evidence"
export default {
  components: {},
  props: {},
  data() {
    return {
      id: 0,
      credit: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 查看存证证书
    lookupHandler() {
      const recordType = 1;
      this.$router.push({ path: '/certificate', query: { id: this.id, recordType } });
    }
  },
  created() { },
  mounted() {
    this.id = Number(this.$route.query.id);
    evidenceQuery({ id: this.id }).then(res => {
      this.credit = res.data;
    })
  },
};
</script>
<style lang="scss" scoped>
.detail {
  &-content {
    max-width: 1440px;
    margin: 0 auto;
    width: 472px;
    margin-left: 460px;
    position: relative;

    // 面包屑导航
    ::v-deep .el-breadcrumb {
      position: absolute;
      top: 40px;
      font-size: 13px;
      color: #001420;

      .el-breadcrumb__inner.is-link {
        color: #93989A;
      }

      .el-breadcrumb__item:last-child .el-breadcrumb__inner {
        font-weight: bold;
        color: #001420;
      }
    }

    .tit {
      font-size: 24px;
      font-weight: bold;
      color: #001420;
      line-height: 32px;
      padding: 98px 0 60px;
    }

    ul {
      margin: 20px 0;

      li {
        display: flex;
        // align-items: center;
        margin-bottom: 40px;
        font-size: 14px;
        font-weight: normal;
        color: #606266;
        line-height: 18px;

        .text {
          flex: 1;
          font-size: 14px;
          font-weight: normal;
          color: #001420;
          line-height: 18px;
          padding-left: 17px;

          &a {
            display: block;
            text-decoration: underline;
          }
        }
      }
    }

    // 查看按钮
    ::v-deep .lookup {
      width: 140px;
      height: 40px;
      margin: 20px 0 60px;
      background: #4080FF;
      border-radius: 4px;
      font-size: 14px;
      font-weight: normal;
      color: #FFFFFF;
    }
  }
}
</style>