<!-- 企业账号申请 -->
<template>
  <div class="application">
    <top-bar />
    <div class="content inner">
      <div class="title">企业账号申请</div>
      <div class="formBox">
        <el-form ref="form" :rules="rules" :model="form" label-width="140px">
          <el-form-item label="企业名称" prop="company_name">
            <el-input @blur="checkFormRequireOnBlur" v-model.trim="form.company_name" placeholder="请输入企业名称" clearable
              maxlength="80"></el-input>
          </el-form-item>

          <el-form-item label="统一社会信用代码" prop="credit_identifier">
            <el-input @blur="checkFormRequireOnBlur" v-model.trim="form.credit_identifier" placeholder="请输入统一社会信用代码"
              clearable maxlength="18"></el-input>
          </el-form-item>

          <el-form-item class="licence" label="营业执照">
            <el-upload accept="image/jpg,image/jpeg,image/png,image/bmp" class="avatar-uploader" action=""
              :show-file-list="false" :on-change="changeAvatar" :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
              <div class="upload">
                <svg-icon icon-class="upload" style="margin-right: 6px; font-size: 18px; color: #606266" />
                <span style="color: #001420">选择文件</span>
              </div>
              <div class="uploadTip" v-if="uploadTip">
                文件不能大于4M<br />或：图片支持jpg、jpeg、png、bmp格式
              </div>
            </el-upload>

            <div class="licence-detail" v-if="licenceName && imageUrl && !uploadTip">
              <div class="left">
                <svg-icon style="margin: 0 10px 0 15px; font-size: 18px" icon-class="lianjie" />
                <!-- {{ licenceName }} -->
                <span class="left-licenseName">{{ licenceName }}</span>

              </div>
              <div class="right">
                <svg-icon style="margin-right: 20px; font-size: 18px" icon-class="shanchu" @click="deleteImg" />
                <div class="preview" @click="preview">预览</div>
              </div>
            </div>

            <div class="tips">
              （图片支持jpg、jpeg、png、bmp格式，大小不超过4M）
            </div>
          </el-form-item>

          <el-form-item label="企业联系人姓名" prop="username">
            <el-input @blur="checkFormRequireOnBlur" v-model.trim="form.username" placeholder="请输入企业联系人姓名" clearable
              maxlength="20"></el-input>
          </el-form-item>

          <el-form-item label="联系人手机号" prop="phone">
            <el-input @blur="checkFormRequireOnBlur" maxlength="11" v-model.trim="form.phone" placeholder="请输入联系人手机号"
              clearable></el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="code">
            <el-input @blur="checkFormRequireOnBlur" v-model.trim="form.code" placeholder="请输入验证码" clearable></el-input>

            <el-button type="text" class="codeBtn" :disabled="disvaliBtn" @click="getCode">{{ valiBtn }}</el-button>
          </el-form-item>

          <el-form-item label="账号名称" prop="account">
            <el-input @blur="checkFormRequireOnBlur" v-model.trim="form.account" placeholder="请输入登录账号名称" clearable
              maxlength="30"></el-input>
          </el-form-item>

          <el-form-item label="登录密码" prop="password">
            <el-input @blur="checkFormRequireOnBlur" v-model.trim="form.password" :type="passwordType"
              placeholder="请设置6-16位登录密码" autocomplete="off"></el-input>

            <div class="showPass" v-if="form.password">
              <svg-icon v-if="passwordType === 'text'" @click="showPass" icon-class="eye"
                style="margin-right: 22px; font-size: 18px; color: #606266" />
              <svg-icon v-else @click="showPass" icon-class="eyeoff"
                style="margin-right: 22px; font-size: 18px; color: #606266" />
            </div>
          </el-form-item>

          <el-form-item label="重复登录密码" prop="password2">
            <el-input @blur="checkFormRequireOnBlur" v-model.trim="form.password2" type="password" placeholder="请再次输入登录密码"
              autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item class="checkBox">
            <el-checkbox v-model="checked" @change="change"></el-checkbox>
            <div class="agreement">
              已阅读并同意《
              <router-link target="_blank" to="/termsOfUse">平台服务协议</router-link>》和《
              <router-link target="_blank" to="/privacyPolicy">隐私协议</router-link>》
            </div>
          </el-form-item>

          <el-form-item>
            <el-button style="margin-bottom: 155px" :disabled="disabled" type="primary"
              @click="onSubmit('form')">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 预览图片 -->
    <div class="mask" v-show="previewFlag" @click="$event => { previewFlag = false; }">
      <img src="" id="show" width="200">
    </div>

  </div>
</template>

<script>
import TopBar from "@/components/topBar.vue";
import { signup, getSmsCode } from "@/api/login";
import { messageParam } from '@/utils/xawz';
import { debounce } from '@/utils/tools';
import { checkVerificationCode, checkPhone } from '@/utils/tools';

export default {
  name: "application",
  components: {
    TopBar,
    // HelloWorld
  },
  props: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.password2 !== "") {
          this.$refs.form.validateField("password2");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    // 验证手机号
    // var checkPhone = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入联系人手机号"));
    //   } else if (!/^1[3-9]\d{9}$/.test(value)) {
    //     callback(new Error("请输入正确的手机号码"));
    //   } else {
    //     callback();
    //   }
    // };

    return {
      valiBtn: "获取验证码",
      disvaliBtn: false, //验证码按钮是否可用
      uploadTip: false, // 图片上传错误提示
      disabled: true,
      checked: false,
      imageUrl: "", //营业执照地址
      licenceName: "", //营业执照名称
      passwordType: "password",
      form: {
        company_name: "", // 企业名称
        credit_identifier: "", //统一社会信用代码
        username: "", //企业联系人姓名
        phone: "", //联系人手机号
        code: "", //手机验证码
        account: "", //登录账号名称
        password: "", //登录密码
        password2: "", //确认登录密码
      },
      rules: {
        company_name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        credit_identifier: [
          { required: true, message: "请输入统一社会信用代码", trigger: "blur", },
          { pattern: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/, message: "统一社会信用代码输入有误", trigger: "blur" }
        ],
        username: [
          { required: true, message: "请填写企业联系人姓名", trigger: "blur" },
        ],
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [
          {
            validator: checkVerificationCode, required: true, trigger: "blur"
          },
        ],
        account: [
          { required: true, message: "请输入登录账号名称", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请设置6-16位登录密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
          { min: 6, max: 16, message: "请设置6-16位登录密码", trigger: "blur" }, //长度限制
        ],
        password2: [
          { required: true, message: "请再次输入登录密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      previewImg: [],
      previewFlag: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 点击图片预览
    preview() {
      console.log("点击了预览")
      this.previewFlag = true;

    },
    changeAvatar(file) {
      console.log(file);
      this.uploadTip = false;
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.imageUrl = file.raw;
      this.licenceName = file.name;
      this.$loading().close();


      this.disabled = true // 提交按钮禁用

      // 提交按钮
      this.$refs.form.validate((isSuccess) => {
        this.disabled = !isSuccess || !this.checked;
      })

      var reads = new FileReader();
      // f=document.getElementById('file').files[0];
      reads.readAsDataURL(this.imageUrl);
      reads.onload = function (e) {
        document.getElementById('show').src = this.result;
      };
    },
    // 营业执照上传成功
    handleAvatarSuccess(res, file) {
      // this.uploadTip = false;
      // this.imageUrl = URL.createObjectURL(file.raw);
      // this.licenceName = file.name;
      // console.log(this.imageUrl);
      // this.$loading().close();
    },
    beforeAvatarUpload(file) {
      this.$loading({
        lock: true,
        text: "图片上传中...",
        background: "rgba(0, 0, 0, 0)",
      });
      const isIMG =
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/bmp";
      const isLt4M = file.size / 1024 / 1024 < 4;

      console.log(!isIMG || !isLt4M);
      if (!isIMG || !isLt4M) {
        this.uploadTip = true;
        this.$loading().close();
      }
      return isIMG && isLt4M;
    },
    // 删除上传的图片
    deleteImg() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.imageUrl = "";
          this.licenceName = "";
          this.disabled = true // 提交按钮禁用
          this.$message({
            type: "success",
            message: "删除成功!",
            ...messageParam
          });
        })
        .catch(() => {
        });
    },
    // 获取手机验证码，并验证手机号码是否正确，倒计时60秒
    getCode: debounce(function () {
      this.$refs["form"].validateField("phone", (err) => {
        if (err) {
          console.log(err);
          return;
        } else {
          getSmsCode({
            phone: this.form.phone, type: "1",// 1:用户注册 2:修改信息 3:创建api
          }).then(res => {
            console.log(res);
          })
          let time = 60;
          let timer = setInterval(() => {
            if (time == 0) {
              clearInterval(timer);
              this.valiBtn = "获取验证码";
              this.disvaliBtn = false;
            } else {


              this.disvaliBtn = true;
              this.valiBtn = time + "秒后重试";
              time--;

            }
          }, 1000);
        }
      });
    }, 1500, true),


    checkFormRequireOnBlur() {
      this.$refs.form.validate((isSuccess) => {
        this.disabled = !(isSuccess && this.imageUrl) || !this.checked;
      })
    },
    // 监听多选框状态变化
    change(newValue) {
      this.$refs.form.validate((isSuccess) => {

        this.disabled = !(isSuccess && this.imageUrl) || !newValue;

      })
    },
    // 点击小眼睛
    showPass() {
      // 点击改变input框的展示方式
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    getFormData(object) {
      const formData = new FormData();
      Object.keys(object).forEach((key) => {
        const value = object[key];
        if (Array.isArray(value)) {
          value.forEach((subValue, i) =>
            formData.append(key + `[${i}]`, subValue)
          );
        } else {
          formData.append(key, object[key]);
        }
      });
      return formData;
    },
    onSubmit(formName) {

      this.$refs[formName].validate((valid) => {
        if (!this.imageUrl) {

          const message = "请上传营业执照！"

          messageParam

          this.$message.error({
            message,
            ...messageParam
          });
        } else if (valid) {
          var formData = new FormData();
          formData.append("company_name", this.form.company_name);
          formData.append("credit_identifier", this.form.credit_identifier);
          formData.append("username", this.form.username);
          formData.append("phone", this.form.phone);
          formData.append("code", this.form.code);
          formData.append("account", this.form.account);
          formData.append("password", this.form.password);
          formData.append("license", this.imageUrl);
          signup(formData).then(res => {
            console.log(res)
            this.$message({
              showClose: true,
              message: "申请提交成功！平台审核通过后，您将收到短信通知。",
              type: "success",
              ...messageParam
            });
            setTimeout(() => {
              this.$router.push("/");
            }, 3000)

          })

          // axios({
          //   method: "POST",
          //   headers: { "content-type": "multipart/form-data" },
          //   url: "http://172.20.67.82:5699/signup",
          // }).then((res) => {
          //   console.log("15151515", res);
          // });
          // return false;
          // // this.$api.signup(formData).then( (res) => {
          // //   console.log(res)
          // // })
          // this.$message({
          //   showClose: true,
          //   message: "申请提交成功！平台审核通过后，您将收到短信通知",
          //   type: "success",
          // });
          // setTimeout( () => {
          //   this.$router.push("/");
          // }, 3000)
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() { },
  mounted() { },
};
</script>

<style lang="scss" scoped>
.application {
  position: relative;
  min-height: 100vh;
}

.application .inner {
  // width: 100%;
  width: 509px;
  // padding: 0 520px 410px;
  box-sizing: border-box;
  margin-left: 440px;

  // margin: 0 auto;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #001420;
    line-height: 32px;
    padding: 60px 0 48px;
  }

  .formBox {
    box-sizing: border-box;

    ::v-deep .el-form-item__label {
      text-align: left;
      font-size: 14px;
      // width: 112px!important;
      padding-right: 14px;
    }

    .el-input,
    ::v-deep .el-input__inner {
      display: block;
      width: 368px;
      height: 48px;
      line-height: 48px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      font-size: 14px;
    }

    ::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
      display: none;
    }

    .el-input {
      border-width: 0;
    }

    // 营业执照
    .licence {
      .upload {
        width: 120px;
        height: 40px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dddddd;
      }

      .uploadTip {
        font-size: 12px;
        font-weight: normal;
        color: #ff0000;
        line-height: 16px;
        position: absolute;
        top: 0;
        right: 0;
        text-align: left;
      }

      &-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 368px;
        height: 32px;
        background: rgba(64, 128, 255, 0.06);
        font-size: 12px;
        font-weight: normal;
        color: #606266;
        line-height: 16px;
        margin-top: 8px;
        position: relative;

        .left {
          display: flex;
          align-items: center;

        }

        .left-licenseName {
          max-width: calc(368px - 18px - 100px);
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap
        }

        .preview {
          cursor: pointer;
          padding: 8px 19px;
          font-size: 12px;
          color: #4080FF;
          position: absolute;
          top: 0;
          right: 30px;
        }
      }

      .tips {
        font-size: 14px;
        font-weight: normal;
        color: #93989a;
        line-height: 20px;
        padding: 18px 0 8px;
      }
    }

    // 获取验证码按钮
    .codeBtn {
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
      color: #4080ff;
    }

    .codeBtn.is-disabled {
      color: #c0c4cc;
    }

    // 密码框小眼睛
    .showPass {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }

    .checkBox {
      margin-top: 28px;

      .agreement {
        display: inline-block;
        margin-left: 6px;

        a {
          text-decoration: underline;
        }
      }
    }
  }


}

// 图片预览弹窗
.mask {
  background: rgba(0, 0, 0, .4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  img {
    width: 600px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
