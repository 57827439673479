import request from "@/utils/http";


// 存证模块

// 新增存证
export function evidenceAdd(data) {
  return request({
    url: "/evidence/create",
    headers: {
      isToken: true,
      "content-type": "multipart/form-data"
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

// 存证列表
export function evidenceList(data) {
  return request({
    url: "/evidence/list",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

// 存证详情
export function evidenceQuery(data) {
  return request({
    url: "/evidence/query",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

// 存证验证
export function validationApi(data) {
  return request({
    url: "/evidence/validation",
    headers: {
      isToken: true,
      "content-type": "multipart/form-data"
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

// 存证验证列表
export function validationList(data) {
  return request({
    url: "/evidence/validation/list",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}



// 查询套餐列表
export function checkFileName(params) {
  return request({
    url: "/evidence/check",
    method: "get",
    params: params,
  });
}
