<template>
  <div class="login">
    <top-bar />
    <div class="inner">
      <div class="login-img"></div>

      <div class="login-form">
        <div class="title">登录</div>
        <div class="noaccount">
          还没有账号,
          <router-link to="application">立即申请</router-link>
        </div>
        <el-form class="formBox" ref="formData" :rules="rules" :model="formData">
          <!-- 登录账号 -->
          <el-form-item prop="name">
            <el-input v-model.trim="formData.name" placeholder="请输入账号名称" @input="inputHandler"></el-input>
          </el-form-item>
          <!-- 登录密码 -->
          <el-form-item prop="password">
            <el-input v-model.trim="formData.password" :type="passwordType" placeholder="请输入登录密码" autocomplete="off"
              @input="inputHandler"></el-input>

            <div class="showPass">
              <svg-icon v-if="passwordType === 'text'" @click="showPass" icon-class="eye"
                style="margin-right: 22px; font-size: 18px; color: #606266;cursor: pointer;" />
              <svg-icon v-else @click="showPass" icon-class="eyeoff"
                style="margin-right: 22px; font-size: 18px; color: #606266;cursor: pointer;" />
            </div>
          </el-form-item>

          <el-form-item>
            <!-- :loading="true" -->
            <el-button :disabled="disabled" type="primary" :loading="loading" @click="onSubmit('formData')">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { login, getInfo } from "@/api/login";
import { setToken } from "@/utils/auth";
import TopBar from "@/components/topBar.vue";
import { messageParam } from '@/utils/xawz';

export default {
  components: { TopBar },
  props: {},
  data() {
    return {
      windowHeight: 0,
      passwordType: "password",
      disabled: true, //登录按钮是否可用
      loading: false,
      formData: {
        name: "",
        password: "",
      },
      rules: {
        name: [{ required: true, message: "请输入账号名称", trigger: "blur" }],
        password: [
          {
            required: true,
            min: 6,
            max: 16,
            message: "请输入6-16位登录密码",
            trigger: "blur",
          }, //长度限制
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 点击小眼睛
    showPass() {
      // 点击改变input框的展示方式
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    // 监听输入框输入值变化
    inputHandler() {
      if (this.formData.name && this.formData.password) {
        this.disabled = false;
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          login(this.formData.name, this.formData.password).then((res) => {
            console.log(res);

            this.loading = false;
            this.$message({
              showClose: true,
              message: "登录成功",
              type: "success",
              ...messageParam
            });
            setToken(res.data.token);
            getInfo().then((info) => {
              setTimeout(() => {
                localStorage.setItem("userInfo", JSON.stringify(info.data));
                this.$router.back();
              }, 1000);
            });
          }).catch(err => {
            this.loading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
  },
  mounted() { },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background: #fff;

  // overflow-y: hidden;
  .inner {
    width: 100%;
    height: 100%;
    background: url("@/assets/img/loginBg.png") repeat center;
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;

    ::v-deep .el-col {
      height: 100%;
    }

    .login-img {
      width: 50%;
      height: 100%;
      background: url("@/assets/img/login.png") no-repeat center left/740px;
    }

    /* 当屏幕宽度大于 1600 像素时应用此样式 */
    @media screen and (min-width: 1600px) {
      .login-img {
        background-size: 100%;
      }
    }

    .login-form {
      width: 50%;
      height: 100%;
      background: #fff;
      padding-top: 25vh;
      padding-left: 10vw;
      box-sizing: border-box;

      .title {
        font-size: 40px;
        font-weight: bold;
        color: #001420;
        line-height: 53px;
      }

      .noaccount {
        font-size: 16px;
        font-weight: normal;
        color: #93989a;
        line-height: 21px;
        padding: 10px 0 40px;

        a {
          font-size: 16px;
          font-weight: normal;
          color: #008efa;
          line-height: 21px;
        }
      }

      .formBox {
        width: 400px;

        ::v-deep .el-form-item {
          margin-bottom: 28px;
        }

        ::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
          display: none;
        }

        ::v-deep .el-input,
        ::v-deep input {
          height: 48px;
          background: #ffffff;
          border-radius: 4px;
          border-width: 0;
        }

        ::v-deep input {
          border: 1px solid rgba(37, 48, 67, 0.4);
          padding: 0 20px;
        }

        ::v-deep button {
          width: 100%;
          margin: 28px auto 56px;
          height: 48px;
          background: #4080ff;
          border-radius: 4px;
          font-size: 20px;

          &.is-disabled {
            border-width: 0;
            background: #cccccc;
          }
        }

        // 密码框小眼睛
        .showPass {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
      }
    }
  }
}
</style>
