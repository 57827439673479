<template>
  <div class="slider">
    <h1>用户中心</h1>

    <div class="menu" v-for="(item, index) in list" :key="index">
      <div class="menu-item" v-if="userInfo.role == item.role || item.common">
        <dl>
          <dt :class="{ 'active': JSON.stringify(item.children).indexOf(currentPath) > 0 }">
            <svg-icon :icon-class="JSON.stringify(item.children).indexOf(currentPath) > 0 ? item.iconActive : item.icon"
              :style="{ 'fontSize': item.size + 'px' }" />{{ item.name }}
          </dt>
          <dd :class="{ 'active': currentPath === data.path || currentPath === data.child }"
            v-for="(data, i) in item.children" :key="i" @click="goPage(data.path)">{{ data.name }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      currentPath: "",// 当前页面的路由
      list: [
        {
          name: "平台管理",
          icon: "platform",
          iconActive: "platform-active",
          size: "18",
          role: "0", // 用户角色 0：管理员 1：机构用户
          common: false, // 是否公共组件
          children: [
            {
              path: "/companyList",
              child: "/companyDeatil",
              name: "企业用户列表",
            },
            {
              path: "/applicationList",
              child: "/applicationDetail",
              name: "新申请列表",
            },
            {
              path: "/user/orderList",
              child: "/user/orderDetail",
              name: "订单列表",
            },
            {
              path: "/admin/api",
              child: "/admin/apiDetail",
              name: "API列表",
            },
          ],
        },
        {
          name: "企业信息",
          icon: "company",
          iconActive: "company-active",
          size: "17",
          role: "0",
          common: true, // 是否公共组件
          children: [
            {
              path: "/user/index",
              child: "",
              name: "基本信息",
            },
            {
              path: "/user/buyRecord",
              child: "",
              name: "购买记录",
            },
          ],
        },

        {
          name: "API管理",
          icon: "api",
          iconActive: "api-active",
          size: "18",
          role: "1",
          common: false, // 是否公共组件
          children: [
            {
              path: "/user/api",
              child: "",
              name: "API列表",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      // 对路由变化作出响应...
      console.log(`to is `, to, 'from is ', from);

      this.currentPath = to.path;
    }
  },
  computed: {

  },
  methods: {

    // 跳转页面
    goPage(path) {
      if (path) {
        this.$router.push(path);
        this.currentPath = path;
      }

    }
  },
  created() { },
  mounted() {
    this.currentPath = this.$router.currentRoute.path;
    console.log('当前页面路由：', this.currentPath);
  },
};
</script>
<style lang="scss" scoped>
.slider {
  width: 200px;
  // height: calc(100vh - 64px);
  height: 100%;
  background: #fff;
  border-right: 1px solid #ebebeb;
  padding: 24px 0;
  // overflow-y: scroll;
  position: fixed;
  z-index: 9;

  h1 {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
    color: #001420;
    line-height: 31px;
    text-align: center;
  }

  .menu {
    margin-bottom: 20px;

    dt {
      height: 54px;
      display: flex;
      align-items: center;
      // justify-content: center;
      padding-left: 34px;
      font-size: 14px;
      font-weight: normal;
      color: #000000;
      line-height: 18px;

      svg {
        margin-right: 8px;
      }

      &.active {
        color: #4080FF;
      }
    }

    dd {
      height: 54px;
      display: flex;
      align-items: center;
      // justify-content: end;
      padding-left: 60px;
      font-size: 14px;
      font-weight: normal;
      color: #93989A;
      line-height: 18px;
      cursor: pointer;

      &.active {
        background: rgba(64, 128, 255, .06);
        color: #4080FF;
        border-right: 4px solid #4080FF;
      }
    }


  }
}
</style>