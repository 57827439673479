<template>
  <div class="certificate" ref="certificate">
    <top-bar />
    <div class="inner">
      <div class="zhengshu" ref="zhengshu">
        <!-- logo -->
        <div class="logo">
          <!-- <img src="@/assets/img/logo.png" alt="" /> -->
          <svg-icon icon-class="logo-black" style="font-size: 101px;"></svg-icon>
        </div>

        <div class="tit">{{ id ? "区块链存证证书" : "电子数据区块链验证" }}</div>
        <ul>
          <li>
            <div class="label">申请{{ recordTypeStr }}方</div>
            <div class="text">{{ company_name || "--" }}</div>
          </li>
          <li>
            <div class="label">文件名称</div>
            <div class="text">{{ credit.file_name }}</div>
          </li>

          <li v-if="id">
            <div class="label">加密算法</div>
            <div class="text">SHA256算法</div>
          </li>
          <li v-if="id">
            <div class="label">区块高度</div>
            <div class="text">{{ credit.block_high }}</div>
          </li>
          <li>
            <div class="label">存证哈希</div>
            <div class="text">{{ credit.file_hash }}</div>
          </li>
          <li v-if="id">
            <div class="label">存证时间</div>
            <div class="text">{{ credit.created_at }}</div>
          </li>
          <li v-if="evidence_id">
            <div class="label">验证结果</div>
            <div class="text">{{ credit.validated_result == 1 ? "验证通过" : "验证不通过" }}</div>
          </li>
          <li v-if="evidence_id">
            <div class="label">说明</div>
            <div class="text">{{ credit.validated_result == 1 ? "区块链上哈希一致" : "区块链上哈希不一致" }}</div>
          </li>
          <li v-if="evidence_id">
            <div class="label">验证时间</div>
            <div class="text">{{ credit.validated_at }}</div>
          </li>
        </ul>
        <!-- 萝卜章 -->
        <div class="seal"><img src="@/assets/img/seal.png" alt="" /></div>
      </div>
      <el-button type="primary" class="restore-btn" @click="saveImg">下载{{ recordTypeStr }}证书
      </el-button>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { evidenceQuery, validationList } from "@/api/evidence"
export default {
  components: {},
  props: {},
  data() {
    return {
      company_name: JSON.parse(localStorage.getItem("userInfo")).company_name,
      credit: {},
      id: "",
      evidence_id: "",
      recordTypeStr: '', // 1存证 2 验证
    };
  },
  watch: {},
  computed: {},
  methods: {
    saveImg() {
      // mymap你要截取的元素 也可以是body  整个页面  **
      let canvasID = this.$refs.zhengshu;
      let that = this;
      let a = document.createElement("a");
      html2canvas(canvasID, {
        useCORS: true,
        scrollY: 0,
        // scale: 8, //按比例增加分辨率 (2=双倍).
        // dpi: window.devicePixelRatio * 8, //设备像素比
        removeContainer: false,
      }).then((canvas) => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        a.style.display = "none";
        document.body.removeChild(dom);
        let blob = that.dataURLToBlob(dom.toDataURL("image/png"));
        a.setAttribute("href", URL.createObjectURL(blob));
        a.setAttribute("download", "下载的图片.png");
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
      });
    },
    dataURLToBlob(dataurl) {
      //ie 图片转格式
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
  created() { },
  mounted() {
    this.id = Number(this.$route.query.id);
    this.evidence_id = Number(this.$route.query.evidence_id);
    this.recordTypeStr = this.$route.query.id ? '存证' : '验证';
    if (this.evidence_id) {
      validationList({ id: this.evidence_id }).then(res => {
        this.credit = res.data.data[0];
      })
    } else {
      evidenceQuery({ id: this.id }).then(res => {
        this.credit = res.data;
      })
    }

  },
};
</script>
<style lang="scss" scoped>
.certificate {
  width: 100%;
  //   height: calc(100vh - 64px);
  min-height: 100vh;
  background: #fbfbfb;

  .inner {
    width: 1136px;
    min-height: calc(100vh - 64px);
    background: #ffffff;
    margin: 0 auto;
    overflow: hidden;

    .zhengshu {
      width: 653px;
      height: 926px;
      margin: 25px auto 0;
      background: url("../../assets/img/certificateBg.png") no-repeat center/contain;
      position: relative;
      padding: 135px 137px 135px 109px;

      .logo {
        width: 101px;
      }

      .tit {
        font-size: 36px;
        font-weight: bold;
        color: #001420;
        line-height: 47px;
        text-align: center;
        padding: 38px 0 26px;
      }

      //   内容
      ul li {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: normal;
        color: #93989A;
        line-height: 18px;
        margin-bottom: 40px;

        .label {
          width: 97px;
          text-align: right;
        }

        .text {
          flex: 1;
          font-size: 14px;
          font-weight: normal;
          color: #001420;
          line-height: 18px;
          margin-left: 16px;
        }
      }

      .seal {
        width: 140px;
        position: absolute;
        right: 90px;
        bottom: 138px;
      }
    }

    ::v-deep .restore-btn {
      display: block;
      width: 140px;
      height: 40px;
      background: #4080ff;
      border-radius: 4px;
      margin: 42px auto;
    }
  }
}
</style>