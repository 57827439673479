<template>
  <div>
    <el-container>
      <el-header>
        <top-bar />
      </el-header>

      <el-container>
        <slider-bar />
        <el-main>
          <AppMain />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import AppMain from "./components/main.vue";
export default {
  components: { AppMain },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
::v-deep .el-main {
  padding: 0;
  .mainBox {
    width: 1240px;
    // padding: 80px 50px 80px 250px;
    padding-left: 200px;
    margin: 80px auto;
    box-sizing: border-box;
    position: relative;
    .mainTit {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 32px;
    }

    .goback {
      cursor: pointer;
      position: absolute;
      top: -50px;
      padding: 10px 0;
      font-size: 14px;
      color: #000000;
      line-height: 18px;
    }
  }
}
</style>