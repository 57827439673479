<template>
  <div class="user" @click="$event => { previewFlag = false; }">
    <el-form class="user-info" ref="form" :rules="rules" :model="userinfo">
      <div class="user-tit">基本信息</div>

      <div class="item">
        <div class="item-label">企业名称</div>
        <div class="item-content">{{ userinfo.company_name }}</div>
      </div>

      <div class="item">
        <div class="item-label">统一社会信用代码</div>
        <div class="item-content">{{ userinfo.credit_identifier }}</div>
      </div>

      <div class="item">
        <div class="item-label">营业执照</div>
        <div class="item-content license">
          <svg-icon style="margin: 0 10px 0 15px; font-size: 18px" icon-class="lianjie" />
          <div class="fileName">{{ userinfo.license_name || '营业执照' }}</div>
          <div v-if="canPreview" class="preview" @click="preview">预览</div>
        </div>
      </div>

      <div class="item" prop="username">
        <div class="item-label">企业联系人</div>
        <el-form-item prop="username" class="item-content">
          <el-input maxlength="20" v-model.trim="userinfo.username" type="text" placeholder="填写企业联系人姓名"></el-input>
        </el-form-item>
      </div>

      <div class="item">
        <div class="item-label">联系人手机号</div>
        <el-form-item prop="phone" class="item-content">
          <el-input maxlength="11" v-model.trim="userinfo.phone" type="text" placeholder="填写企业联系人手机号"></el-input>
        </el-form-item>
      </div>

      <div class="item">
        <div class="item-label">手机号验证</div>
        <el-form-item class="item-content" prop="code">
          <el-input maxlength="6" v-model.trim="userinfo.code" type="text" placeholder="请输入手机验证码"></el-input>

          <el-button type="text" class="codeBtn" :disabled="disvaliBtn" @click="getCode">{{ valiBtn }}</el-button>
        </el-form-item>
      </div>

      <div class="item">
        <div class="item-label">登录账号名称</div>
        <div class="item-content">{{ userinfo.account }}</div>
      </div>

      <div class="item">
        <div class="item-label">登录密码</div>
        <el-form-item class="item-content" prop="password">
          <el-input v-model.trim="userinfo.password" :type="passwordType" placeholder="请设置6-16位登录密码"></el-input>

          <div class="showPass" v-if="userinfo.password">
            <svg-icon v-if="passwordType === 'text'" @click="showPass" icon-class="eye"
              style="margin-right: 22px; font-size: 18px; color: #606266" />
            <svg-icon v-else @click="showPass" icon-class="eyeoff"
              style="margin-right: 22px; font-size: 18px; color: #606266" />
          </div>
        </el-form-item>
      </div>

      <div class="item">
        <div class="item-label"></div>
        <div class="item-content">
          <el-button class="updateBtn" type="primary" @click="update('form')">更新</el-button>
        </div>
      </div>
    </el-form>

    <!-- 预览图片 -->
    <viewer :images="images" @inited="inited" class="viewer" ref="viewer">
      <template #default="scope">
        <img v-for="src in scope.images" v-show="previewFlag" :src="src" :key="src">
      </template>
    </viewer>
  </div>
</template>

<script>
import { getSmsCode } from "@/api/login"
import { profileEdit, getuserInfo, getuser } from "@/api/user";
import { messageParam } from '@/utils/xawz';
import { checkVerificationCode, checkPhone, debounce } from '@/utils/tools';

export default {
  components: {},
  props: {},
  data() {
    // 验证手机号
    // var checkPhone = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入联系人手机号"));
    //   } else if (!/^1[3-9]\d{9}$/.test(value)) {
    //     callback(new Error("请输入正确的手机号码"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      userinfo: {
        password: "", // 密码
        code: "", // 短信验证码
      },
      valiBtn: "获取验证码",
      disvaliBtn: false, //验证码按钮是否可用
      passwordType: "password",
      // userinfo: {
      //   account: "上海寻梦科技有限公司", //登录账号名称
      //   company_name: "锦丰集团2021财年金融预算方案", // 公司名称
      //   created_at: "string", // 用户加入时间
      //   credit_identifier: "91310105090037252C", // 统一社会信用代码
      //   is_auth: true, // 是否通过认证
      //   license: "2018营业执照.png", // 营业执照
      //   phone: "15589545623", // 联系人手机号
      //   role: 0, // 用户角色 0：管理员 1：机构用户
      //   user_id: 0, // 用户id
      //   username: "张三123", // 联系人名称
      //   password: "", // 密码
      //   code: "", // 短信验证码
      // },
      rules: {
        username: [
          { required: true, message: "请输入企业联系人姓名", trigger: "blur" },
        ],
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [
          { validator: checkVerificationCode, required: true, trigger: "blur" }
        ],
        password: [
          { required: true, message: "请设置6-16位登录密码", trigger: "blur" },
          { min: 6, max: 16, message: "请设置6-16位登录密码", trigger: "blur" }, //长度限制
        ],
      },
      previewFlag: false,
      images: [],

    };
  },
  computed: {
    canPreview: function () { // 是否可以显示预览
      return ['.png', '.jpg', '.jpeg', '.bmp', '.gif', '.webp', '.psd', '.svg', '.tiff'].some(type => {
        return this.userInfo?.license_name?.toLowerCase().includes(type)
      })
    }
  },
  methods: {
    // 初始化 viewer
    inited(viewer) {
      this.$viewer = viewer;
      console.log(this.$viewer)
    },
    // 点击图片预览
    preview() {
      console.log("点击了预览")
      this.previewFlag = true;
      this.$viewer.show();
    },
    // 获取手机验证码，并验证手机号码是否正确，倒计时60秒
    getCode: debounce(function () {
      this.$refs["form"].validateField("phone", (err) => {
        if (err) {
          console.log(err);
          return;
        } else {
          // 发送短信验证码接口
          getSmsCode({
            phone: this.userinfo.phone,
            type: "2", // 1:用户注册 2:修改信息 3:创建api
          }).then(() => {
            let time = 60;
            let timer = setInterval(() => {
              if (time == 0) {
                clearInterval(timer);
                this.valiBtn = "获取验证码";
                this.disvaliBtn = false;
              } else {
                this.disvaliBtn = true;
                this.valiBtn = time + "秒后重试";
                time--;
              }
            }, 1000);
          })
        }
      });
    }, 1500, true),
    // 点击小眼睛
    showPass() {
      // 点击改变input框的展示方式
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    // 更新
    update(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          profileEdit(this.userinfo).then(() => {
            this.$message({
              showClose: true,
              message: "更新信息成功",
              type: "success",
              ...messageParam
            });
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() { },
  mounted() {
    let role = JSON.parse(localStorage.getItem("userInfo")).role, user_id = Number(JSON.parse(localStorage.getItem("userInfo")).user_id);
    // 管理员权限
    if (role == 0) {
      getuserInfo({
        id: user_id
      }).then(res => {
        console.log(res.data)
        this.userinfo = { ...this.userinfo, ...res.data };
        this.images.push(this.userinfo.license)
        localStorage.setItem("userInfo", JSON.stringify(this.userinfo));
      })
    } else {
      getuser({
        id: user_id
      }).then(res => {
        console.log(res.data)
        this.userinfo = { ...this.userinfo, ...res.data };
        this.images.push(this.userinfo.license)
        localStorage.setItem("userInfo", JSON.stringify(this.userinfo));
      })
    }



    // this.userinfo = {...this.userinfo, ...JSON.parse(localStorage.getItem("userInfo"))};
    // this.images.push(this.userinfo.license)

  },
};
</script>
<style lang="scss" scoped>
.user {
  &-info {
    // width: 862px;
    width: 664px;
    // padding: 80px 0 177px 398px;
    padding-left: 200px;
    margin: 80px auto 177px;
    box-sizing: border-box;

    .user-tit {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 32px;
      margin-bottom: 48px;
    }

    .item {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      color: #606266;
      line-height: 18px;

      &-label {
        flex: 1;
      }

      .license {
        height: 32px;
        background: rgba(64, 128, 255, 0.06);
        font-size: 12px;
        color: #606266;
        display: flex;
        align-items: center;
        position: relative;

        .fileName {
          flex: 1;
          padding-right: 60px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .preview {
          font-size: 14px;
          color: #4080FF;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          margin: auto;
        }
      }

      ::v-deep .el-form-item {
        margin-bottom: 0;
      }

      ::v-deep &-content {
        color: #001420;
        width: 340px;
        position: relative;

        // 获取验证码按钮
        .codeBtn {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          margin: auto;
          color: #4080ff;
        }

        .codeBtn.is-disabled {
          color: #c0c4cc;
        }

        // 密码框小眼睛
        .showPass {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
      }
    }

    .updateBtn {
      margin: 26px 0;
      width: 120px;
      height: 40px;
      background: #4080ff;
      border-radius: 4px;
      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
    }
  }
}
</style>