<template>
  <div class="topBar">
    <div class="inner">
        <div class="topBar-left">
            <router-link tag="div" to="/" class="logo">
                <!-- <img src="@/assets/img/logo.png"> -->
                <svg-icon v-if="logo == 2" icon-class="logo-white" style="font-size: 101px;"></svg-icon>
                <svg-icon v-else icon-class="logo-black" style="font-size: 101px;"></svg-icon>
                
            </router-link>
            <ul>
                <router-link tag="li" to="/">首页</router-link>
                <router-link tag="li" to="/credit/index">区块链存证</router-link>
                <router-link tag="li" to="/goods/index">产品服务</router-link>
                <router-link tag="li" to="/aboutUs">关于我们</router-link>
            </ul>
        </div>
        <div class="topBar-right">
           
            <!-- 已登录 -->
            <div v-if="token" class="username">
                <div>{{ "hi，" + userInfo.company_name }}<i class="el-icon-arrow-down" style="color: #606266;padding-left: 11px;"></i></div>
                
                <!-- 弹层 -->
                <ul class="mask">
                    <li @click="$event => { this.userInfo.role == 0 ? this.$router.push('/companyList'): this.$router.push('/user/index')}">用户中心</li>
                    <li @click="logoutHandler">退出登录</li>
                </ul>
            </div>

             <!-- 未登录 -->
             <div class="login" v-else>
                <router-link to="/application" style="padding: 0 48px;">企业账号申请</router-link>
                <router-link to="/login">登录</router-link>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {logout, getInfo} from "@/api/login"
import {getToken, removeToken} from "@/utils/auth"
export default {
    props: {
        logo: {
            type: String,
            default: "",
        }
    },  
    data(){
        return {
            token: getToken(),
            userInfo: JSON.parse(localStorage.getItem("userInfo")),
        }
    },
    mounted(){
        // console.log(this.userInfo)
    },
    methods: {
        // 退出登录
        logoutHandler(){
            logout().then(res=> {
                removeToken();
                localStorage.clear();//清除缓存
                this.$router.push('/login')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .topBar{
        width: 100%;
        height: 64px;
        .inner{
            width: 100%;
            height: 64px;
            padding: 0 70px;
            background: #FFFFFF;
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;
        }
        &-left{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo{
                width: 110px;
                cursor: pointer;
            }
            ul{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 50px;
                .router-link-exact-active{
                color:#4080FF
            }
                li{
                    cursor: pointer;
                    padding: 0 24px;
                    font-size: 16px;
                    font-weight: normal;
                    color: #001420;
                    line-height: 21px;
                }
            }
        }

        &-right{
            font-size: 16px;
            color: #001420; 
            .username{
                position: relative;
                padding: 20px 0;
                min-width: 120px;
                
                .mask{
                    transition: linear 50s;
                    width: 163px;
                    background: #FFFFFF;
                    box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.09);
                    border-radius: 2px;
                    // padding: 10px 0;
                    position: absolute;
                    top: 50px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    display: none;
                    li{
                        cursor: pointer;
                        // padding: 14px 0;
                        text-align: center;
                        font-size: 14px;
                        color: #93989A;
                        // line-height: 18px;
                        line-height: 60px;
                        &:hover{
                            background: rgba(64, 128, 255, .06);
                            color: #4080FF;
                        }
                    }
                }

                &:hover{
                    .mask{
                        display: block;
                    }
                }
            }
        }
        
    }

    // 
    .home{
        .inner{
            background: #EBF2FF;
            backdrop-filter: blur(14px);
            box-shadow: none;
        }
        .active .inner{
            background: rgba(255,255,255,0.06);
        }
    }
    .goods-active{
        .inner{
            background: rgba(235,242,255,0.41);
            backdrop-filter: blur(14px);
            box-shadow: none;
        }
    }

    .credit{
        .inner{
            background: rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(14px);
            box-shadow: none;
        }
    }

    .about .active{
        height: auto;
        .inner{
            background: rgba(0, 0, 0, .4);
            // background: rgba(255, 255, 255, .06);
            backdrop-filter: blur(14px);
            box-shadow: none;
            
        }
        .topBar-left ul li, .topBar-right, .login a{
            color: #fff;
        }
        .topBar-left ul li.router-link-exact-active{
            color:#4080FF
        }
    }
</style>