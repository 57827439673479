<template>
  <div class="home">
    <top-bar :class="{ 'active': scrollTop > 400 }" />

    <div class="banner">
      <div class="inner">
        <div class="bannerMain">
          <!-- <div class="subTit">芯安链 · XAChain</div> -->
          <div class="subTit">芯安链</div>
          <h1>区块链<em>可信存证</em></h1>
          <p>芯安链可信存证产品采用区块链技术，针对企业客户大量电子数据（包含图<br>
            片、文件、音频、视频等）需多方共识、不可篡改的业务需求而设计打造，<br>
            提供一整套“信任”解决方案。</p>
          <div class="groupBtn">
            <el-button type="primary" class="sampleBtn" @click="goPage(1)">立即体验</el-button>
            <el-button type="primary" class="checkBtn" plain @click="goPage(2)">数据查验</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品优势 -->
    <div class="advantage">
      <div class="partTit">
        <!-- <p>PART.1</p> -->
        <div class="part" style="width: 132px;margin: 0 auto;"><img src="@/assets/img/part1.png" alt=""></div>
        <h1>产品优势</h1>
      </div>
      <div class="inner">
        <el-row :gutter="16">
          <el-col :span="6">
            <div class="item">
              <img src="../assets/img/01.png" alt="">
              <div class="tit">可信存证</div>
              <p>基于区块链技术，将数据存储于链上，保证数据安全、不被篡改。</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <img src="../assets/img/02.png" alt="">
              <div class="tit">安全可靠</div>
              <p>采用加密算法，保障数据隐私安全。</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <img src="../assets/img/03.png" alt="">
              <div class="tit">适用场景丰富</div>
              <p>平台适用于电子合同、版权等多种场景，以保证业务流程合规、可信。</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <img src="../assets/img/04.png" alt="">
              <div class="tit">简单易用</div>
              <p>通用、规范的API，打造适配易用的区块链存证服务。</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>


    <!-- 应用场景 -->
    <div class="scene">
      <div class="partTit">
        <!-- <p>PART.2</p> -->
        <div class="part" style="width: 132px;margin: 0 auto;"><img src="@/assets/img/part2.png" alt=""></div>
        <h1>应用场景</h1>
      </div>

      <div class="inner">
        <el-row :gutter="16">
          <el-col :span="8">
            <div class="item">
              <img src="../assets/img/scene4.jpg" alt="">
              <div class="tit">版权保护</div>
              <p>基于区块链技术，为原创作者和机构提<br>供版权保护、帮助版权人维护合法权益</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item">
              <img src="../assets/img/antiFake.png" alt="">
              <div class="tit">防伪溯源</div>
              <p>基于区块链存证，实现商品全流程信息记<br>录，消费者可便捷地进行信息溯源查询</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item">
              <img src="../assets/img/supplyFinance.png" alt="">
              <div class="tit">供应链金融</div>
              <p>合同、仓单、票据、采购单等数据上链，<br>数据可信，融资高效</p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="8">
            <div class="item">
              <img src="../assets/img/carbonStep.png" alt="">
              <div class="tit">碳足迹溯源存证</div>
              <p>采用区块链+物联网技术，将物联设备采集数<br>据区块链上存证，并实现数据的精准追溯</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item">
              <img src="../assets/img/scene5.jpg" alt="">
              <div class="tit">电子合同</div>
              <p>基于区块链存证，对电子合同全流程上链<br>存证，保证合同不被篡改，有力保障履约</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item">
              <img src="../assets/img/scene6.jpg" alt="">
              <div class="tit">可信广告</div>
              <p>广告传播应用区块链技术构建全新的商业模式，<br>以最低的成本解决广告传播中各方的信任问题<br></p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <footer-bar />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { getToken } from "@/utils/auth"
export default {
  name: 'indexView',
  components: {
    // HelloWorld
  },
  data() {
    return {
      // token: localStorage.getItem('token'),
      token: getToken(),
      scrollTop: 0,
    }
  },
  methods: {
    goPage(flage) {
      if (this.token) {
        this.$router.push('/credit/index');
      } else {
        this.$router.push('/login');
      }
    },
    // 监听滚动事件
    allScrollTop(e) {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log("监听滚动条的滚动距离", scrollTop);
      this.scrollTop = scrollTop;
    }
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.allScrollTop);
  }
}
</script>
<style lang="scss" scoped>
// 顶部bannner
.banner {
  width: 100%;
  height: 488px;
  background: #EBF2FF;

  .inner {
    width: 100%;
    height: 100%;
    background: url("../assets/img/banner.jpg") no-repeat right center/contain;

    .bannerMain {
      width: 1140px;
      margin: 0 auto;

      .subTit {
        font-size: 20px;
        font-weight: 400;
        color: #001420;
        line-height: 28px;
        letter-spacing: 1px;
        padding: 64px 0 14px;

      }

      h1 {
        color: #001420;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 2px;

        em {
          color: #4080FF;
        }
      }

      p {
        font-size: 16px;
        font-weight: normal;
        // color: #001420;
        color: rgba(0, 20, 32, .6);
        line-height: 30px;
        padding: 14px 0 40px;
      }

      .groupBtn {
        .sampleBtn {
          width: 120px;
          height: 40px;
          margin-right: 30px;
          background: #4080FF;
          border-radius: 4px;
          font-size: 15px;
          color: #FFFFFF;
        }

        .checkBtn {
          width: 120px;
          height: 40px;
          border-radius: 4px;
          background: transparent;
          border: 1px solid #4081FF;
          font-size: 15px;
          font-weight: normal;
          color: #4080FF;
        }
      }
    }
  }
}

// 标题
.partTit {
  padding: 90px 0 0;
  text-align: center;

  h1 {
    font-size: 36px;
    font-weight: bold;
    color: #001420;
    line-height: 50px;
  }

  p {
    font-size: 36px;
    font-weight: 800;
    color: rgba(0, 20, 32, 0);
    line-height: 50px;
    letter-spacing: 4px;
    -webkit-text-stroke: 1px #7E7E7E;
    text-stroke: 1px #7E7E7E;
  }

}


// 产品优势
.advantage {
  width: 100%;
  height: 569px;
  background: url("../assets/img/banner-2.png") no-repeat center/100% 100%;

  .inner {
    width: 1140px;
    margin: 94px auto 0;
  }

  .item {
    width: 272px;
    height: 224px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px #EAF0FF;
    border-radius: 10px;
    padding: 67px 30px 0 30px;
    font-size: 16px;
    font-weight: 400;
    color: #86909C;
    position: relative;

    .tit {
      padding-bottom: 18px;
      font-size: 24px;
      color: #001420;
    }

    img {
      width: 120px;
      position: absolute;
      left: 0;
      top: -46px
    }
  }

}

// 应用场景
.scene {
  .inner {
    width: 1140px;
    margin: 40px auto;
    padding-bottom: 70px;

    .item {
      margin-bottom: 50px;
      text-align: center;

      img {
        width: 168px;
        height: 168px;
        margin: 0 auto;
      }

      .tit {
        padding: 11px 0 13px;
        font-size: 20px;
        color: #001420;
        line-height: 29px;
      }

      p {
        font-size: 14px;
        color: #86909C;
        line-height: 19px;
      }
    }
  }
}
</style>
